import axios from "axios";
import { ServiceConfig } from "./service";
import { cookies, getCookieName, decrypt, encrypt, getCookieDomain, getAuthResourceName } from "src/helpers";
import moment from "moment";

import { store } from "src/store";
import { authActions } from "src/store/auth/actions";
import { snackbarActions } from "src/store/snackbar/actions";
import { refreshTokenActions } from "src/store/refreshToken/actions";

/**
 * Interceptors for axios
 * 
 * request: add token to header if exists
 * 
 * response: refresh token
 * @param serviceConfig Config from Service class
 * @param self Equivalent to this from Service class
 */
export function interceptors(serviceConfig: ServiceConfig, self: any) {

    if (!Boolean(serviceConfig?.isPublic)) {
        self.service.interceptors.request.use(async (config: any) => {
            const token = cookies.get(getCookieName())?.replace('%22', '');

            if (serviceConfig?.isOptionalToken) {
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`;
                    return config;
                } else {
                    return config;
                }
            }

            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
                return config;
            } else {
                logout()
                throw new axios.Cancel("Operation canceled");
            }
        });
    }

    self.service.interceptors.response.use(
        (response: any) => {
            return response;
        },
        async (error: any) => {
            const originalRequest = error.config;
            const isRefreshingToken = store.getState()?.refreshToken?.isRefreshingToken;
            const failedQueue = [...store.getState()?.refreshToken?.failedRequestsQueue];
            const refreshTokenUrl = `${import.meta.env.REACT_APP_IAM_URL}/customers/oauth/token`;

            if (originalRequest?.url == "/customers/oauth/token") {
                logout('Tu sesión ha expirado. Por favor, inicia sesión nuevamente')
                return Promise.reject(error);
            }

            if (error.response?.status === 401 && !originalRequest._retry) {
                if (isRefreshingToken) {
                    return new Promise((resolve, reject) => {
                        failedQueue.push({ resolve, reject })
                        store.dispatch(refreshTokenActions.set('failedRequestsQueue', failedQueue))
                    }).then(token => {
                        originalRequest.headers['Authorization'] = 'Bearer ' + token;
                        return self.service(originalRequest);
                    }).catch(err => {
                        return Promise.reject(err);
                    })
                }

                originalRequest._retry = true;
                store.dispatch(refreshTokenActions.set('isRefreshingToken', true))

                const refresh_token = decrypt(localStorage.getItem(getAuthResourceName('refresh_token')) || '');
                const client_id = decrypt(localStorage.getItem(getAuthResourceName('client_id')) || '');
                const code = decrypt(localStorage.getItem(getAuthResourceName('code')) || '');

                return new Promise((resolve, reject) => {
                    self.service.post(refreshTokenUrl, {
                        client_id,
                        code,
                        refresh_token,
                        grant_type: 'refresh_token',
                    }).then((response: any) => {
                        const data = response?.data?.data?.attributes;

                        if (data?.access_token) {
                            localStorage.setItem(getAuthResourceName('refresh_token'), encrypt(data?.refresh_token));

                            cookies.set(getCookieName(), JSON.stringify(data?.access_token), {
                                path: '/',
                                domain: getCookieDomain(),
                                expires: moment().add(28800, 'seconds').utc().toDate(),
                            });

                            originalRequest.headers['Authorization'] = 'Bearer ' + data?.access_token;
                            self.processQueue(null, data?.access_token);
                            resolve(self.service(originalRequest));

                        } else {
                            logout()
                            self.processQueue(error, null);
                            reject(error);
                            window.location.reload();
                        }

                    }).catch((err: any) => {
                        logout()
                        self.processQueue(error, null);
                        reject(error);
                        window.location.reload();
                    }).finally(() => {
                        store.dispatch(refreshTokenActions.set('isRefreshingToken', false))
                    })

                });
            }

            return Promise.reject(error);
        }
    )
}

function logout(customMessage?: string) {
    store.dispatch(snackbarActions.add('info', customMessage || 'Tu sesión ha expirado. Por favor, inicia sesión nuevamente'))
    localStorage.clear()
    cookies.remove(getCookieName(), {
        path: '/',
        domain: getCookieDomain(),
    });
    store.dispatch(authActions.logout());
}